:root {
    --dr: calc(1.618 * 1vh);
  }
  
  @-webkit-keyframes growin {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes growin {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @-webkit-keyframes slideup {
    0% {
      -webkit-transform: translateY(1000%);
              transform: translateY(1000%);
    }
    80% {
      -webkit-transform: translateY(1000%);
              transform: translateY(1000%);
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }
  }
  
  @keyframes slideup {
    0% {
      -webkit-transform: translateY(1000%);
              transform: translateY(1000%);
    }
    80% {
      -webkit-transform: translateY(1000%);
              transform: translateY(1000%);
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }
  }
  
  @-webkit-keyframes popin {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    50% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    60% {
      -webkit-transform: scale(2);
              transform: scale(2);
    }
    70% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    80% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }
    90% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    95% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes popin {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    50% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    60% {
      -webkit-transform: scale(2);
              transform: scale(2);
    }
    70% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    80% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }
    90% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    95% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @-webkit-keyframes fadein {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fadein2 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadein2 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes invis {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes invis {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .fader2 {
    -webkit-animation: fadein2 4s ease 0s normal 1;
            animation: fadein2 4s ease 0s normal 1;
  }
  
  @-webkit-keyframes slidedown {
    0% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }
    80% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }
  }
  
  @keyframes slidedown {
    0% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }
    80% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }
  }
  
  html,
  body {
    overflow-x: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  body {
    /* background-color: black; */
    width: 100vw;
    max-width: 100vw;
    margin: 0vh;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 100vw;
    font-family: "Trebuchet MS", Helvetica, sans-serif;
  }
  
  .mainmenu {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    padding-top: 10vh;
    z-index: 10;
    position: relative;
    overflow: hidden;
  }
  
  .bingborder {
    text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000,
      1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
    filter: progid:DXImageTransform.Microsoft.Glow(Color=#000000,Strength=1); /* IE<10 */
  }
  
  .half {
    /* transform: translate(0, -100%); */
    z-index: 5;
    position: relative;
  }
  
  .half90 {
    position: absolute;
    top: 5vh;
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%);
    z-index: 5;
  }
  
  .half75 {
    position: absolute;
    top: 5vh;
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%);
    z-index: 5;
  }
  
  .rotholder {
    position: absolute;
    margin-left: 43.5vw;
    /* bottom:-7vw; */
    -webkit-animation: 1s 1 growin;
            animation: 1s 1 growin;
  }
  
  .rot {
    height: 15vw;
    width: 15vw;
    /* margin-left:50vw; */
    /* transform: translateX(-50%); */
    position: relative;
    z-index: 4;
  }
  
  .rot90 {
    margin-top: 5vh;
    height: 15vw;
    width: 15vw;
  }
  
  .triangle {
    border-bottom: 10vw solid;
    border-left: 5vw solid transparent;
    border-right: 5vw solid transparent;
    position: absolute;
    left: 2.5vw;
    margin-top: 1.045vw;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    height: 0;
    width: 0;
    z-index: 3;
  }
  
  .trianglea {
    border-bottom: 10.5vw solid black;
    border-left: 5vw solid transparent;
    border-right: 5vw solid transparent;
    position: absolute;
    left: 2.5vw;
    margin-top: 0.57075vw;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    height: 0;
    width: 0;
    z-index: 2;
  }
  
  .triangleb {
    border-bottom: 11vw solid white;
    border-left: 5vw solid transparent;
    border-right: 5vw solid transparent;
    position: absolute;
    left: 2.5vw;
    /* bottom: -7vw; */
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    height: 0;
    width: 0;
    z-index: 1;
  }
  
  .triangle90 {
    border-bottom: 8vw solid;
    border-left: 4vw solid transparent;
    border-right: 4vw solid transparent;
    position: absolute;
    left: 2.5vw;
    bottom: -7vw;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    height: 0;
    width: 0;
    z-index: 100;
  }
  
  .trianglea90 {
    border-bottom: 8.5vw solid black;
    border-left: 4vw solid transparent;
    border-right: 4vw solid transparent;
    position: absolute;
    left: 2.5vw;
    bottom: -7vw;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    height: 0;
    width: 0;
    z-index: 99;
  }
  
  .triangleb90 {
    border-bottom: 9vw solid white;
    border-left: 4vw solid transparent;
    border-right: 4vw solid transparent;
    position: absolute;
    left: 2.5vw;
    bottom: -7vw;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    height: 0;
    width: 0;
    z-index: 98;
  }
  
  /* .burstholds {
    position: relative;
    left: 0.5vw;
    text-align: center;
    margin-left: 5vw;
    margin-top: -5vw;
    width: 12vw;
  } */
  
  /* .burstholds75 {
    position: relative;
    margin-left: 7.5vw;
    width: 12vw;
  } */
  
  .burst90 {
    position: relative;
    text-align: center;
    left: 3vw;
    top: 0.3vw;
    font-size: 1.3vw;
    width: 7vw;
    z-index: 99;
  }
  
  .rots90 {
    /* height: 15vw;
    width: 15vw; */
    -webkit-transform: scale(0.65) translateY(-5%);
            transform: scale(0.65) translateY(-5%);
    /* margin-left:5vw; */
    margin-top: -2vw;
    position: relative;
    z-index: -4;
  }
  
  .triangles90 {
    border-bottom: 8vw solid;
    border-left: 4vw solid transparent;
    border-right: 4vw solid transparent;
    position: absolute;
    left: 2.5vw;
    bottom: -7vw;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    height: 0;
    width: 0;
    z-index: 100;
  }
  
  .triangleas90 {
    border-bottom: 8.5vw solid black;
    border-left: 4vw solid transparent;
    border-right: 4vw solid transparent;
    position: absolute;
    left: 2.5vw;
    bottom: -7vw;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    height: 0;
    width: 0;
    z-index: 99;
  }
  
  .triangleabs90 {
    border-bottom: 9vw solid white;
    border-left: 4vw solid transparent;
    border-right: 4vw solid transparent;
    position: absolute;
    left: 2.5vw;
    bottom: -7vw;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    height: 0;
    width: 0;
    z-index: 98;
  }
  
  .triangle1 {
    /* transform: rotate(-45deg); */
  }
  .triangle2 {
    -webkit-transform: rotate(27.6923deg) translateZ(1px);
            transform: rotate(27.6923deg) translateZ(1px);
    outline: 1px solid transparent;
    /* transform: translateZ(1px) rotate(-45deg); */
    /* background-clip: padding-box; */
  }
  .triangle3 {
    -webkit-transform: rotate(-27.6923deg) translateZ(1px);
            transform: rotate(-27.6923deg) translateZ(1px);
    outline: 1px solid transparent;
  }
  .triangle4 {
    -webkit-transform: rotate(55.3846deg) translateZ(1px);
            transform: rotate(55.3846deg) translateZ(1px);
  }
  .triangle5 {
    -webkit-transform: rotate(-55.3846deg) translateZ(1px);
            transform: rotate(-55.3846deg) translateZ(1px);
  }
  .triangle6 {
    -webkit-transform: rotate(83.0769deg) translateZ(1px);
            transform: rotate(83.0769deg) translateZ(1px);
  }
  .triangle7 {
    -webkit-transform: rotate(-83.0769deg) translateZ(1px);
            transform: rotate(-83.0769deg) translateZ(1px);
  }
  .triangle8 {
    -webkit-transform: rotate(110.7692deg) translateZ(1px);
            transform: rotate(110.7692deg) translateZ(1px);
  }
  .triangle9 {
    -webkit-transform: rotate(-110.7692deg) translateZ(1px);
            transform: rotate(-110.7692deg) translateZ(1px);
  }
  .triangle10 {
    -webkit-transform: rotate(138.4615deg) translateZ(1px);
            transform: rotate(138.4615deg) translateZ(1px);
  }
  .triangle11 {
    -webkit-transform: rotate(-138.4615deg) translateZ(1px);
            transform: rotate(-138.4615deg) translateZ(1px);
  }
  .triangle12 {
    -webkit-transform: rotate(166.1538deg) translateZ(1px);
            transform: rotate(166.1538deg) translateZ(1px);
  }
  .triangle13 {
    -webkit-transform: rotate(-166.1538deg) translateZ(1px);
            transform: rotate(-166.1538deg) translateZ(1px);
  }
  
  .menubing {
    font-size: 5.8vw;
    line-height: 2vw;
  }
  
  .title {
    position: relative;
    color: whitesmoke;
    text-align: center;
    /* padding-bottom:7.5vh; */
    /* padding-left:7.5vw; */
    z-index: 101;
    /* margin-left:7.5vw; */
    margin-top: -9.5vw;
    /* transform: translateX(-50%); */
    -webkit-animation: popin 2.5s ease-in-out 0.1s normal 1;
            animation: popin 2.5s ease-in-out 0.1s normal 1;
  }
  
  .menusubtitle {
    text-align: center;
    font-size: 1.1vw;
    line-height: 0;
    color: black;
    font-weight: bolder;
    -webkit-animation: fadein 5s ease 0.2s normal 1;
            animation: fadein 5s ease 0.2s normal 1;
  }
  
  .title90 {
    -webkit-transform: rotate(-22.5deg);
            transform: rotate(-22.5deg);
    position: relative;
    top: 20vmin;
    left: -2vmin;
    color: whitesmoke;
    text-align: center;
    /* margin-top:7.5vmin; */
    /* padding-left:7.5vw; */
    z-index: 101;
  }
  
  .title75 {
    -webkit-transform: rotate(-22.5deg);
            transform: rotate(-22.5deg);
    position: relative;
    top: 20vmin;
    left: -2vmin;
    color: whitesmoke;
    text-align: center;
    /* margin-top:7.5vmin; */
    /* padding-left:7.5vw; */
    z-index: 101;
  }
  
  .startitle90 {
    font-size: 7.5vmin;
    margin-top: 8vmin;
    /* line-height:3vh; */
  }
  
  .startitle75 {
    font-size: 7.5vmin;
    margin-top: 8vmin;
    /* line-height:3vh; */
  }
  
  .starsub90 {
    color: black;
    font-size: 1.3vmin;
    font-weight: bold;
    margin-left: 1.4vmin;
    margin-top: -5vmin;
  }
  
  .starsub75 {
    color: black;
    font-size: 1.3vmin;
    font-weight: bold;
    margin-left: 1.4vmin;
    margin-top: -5vmin;
  }
  
  .maincont {
    border-radius: 3vh;
    background-color: white;
    background: white;
    z-index: 10;
    width: 46vw;
    min-width: 46vw;
    max-width: 46vw;
    height: 26.3vh;
    min-height: 26.3vh;
    max-height: 26.3vh;
    margin-left: 27vw;
    margin-top: 19vw;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    overflow: hidden;
    -webkit-animation: slideup 2s ease 0s normal 1;
            animation: slideup 2s ease 0s normal 1;
  }
  
  .mainbox {
    /* grid-area: bc; */
    text-align: center;
    border: 1px solid black;
    position: absolute;
    border-radius: 3vh;
    /* top: 55vh;
    left: 30vw; */
    width: 45vw;
    height: 25vh;
    margin-left: 0.4vw;
    margin-top: 0.5vh;
    color: white;
    z-index: -1;
    display: grid;
    grid-template-rows: 3fr 4fr;
    grid-template-areas:
      "fe"
      "ff";
  }
  
  .mainsubtitle {
    font-size: 4vmin;
  }
  
  .menuholder {
    display: grid;
    margin-top: -8vh;
    height: 18vh;
    grid-template-rows: 5vh 5vh;
    grid-template-areas:
      "a b"
      "c d";
  }
  
  .main75 {
    grid-area: a;
    font-size: 3vmin;
    padding-top: 4vh;
  }
  
  .main90 {
    grid-area: b;
    font-size: 3vmin;
    padding-top: 4vh;
  }
  
  .mainprint75 {
    grid-area: c;
    font-size: 2vmin;
    padding-top: 4vh;
  }
  
  .mainprint90 {
    grid-area: d;
    font-size: 2vmin;
    padding-top: 4vh;
  }
  
  .main75l {
    color: white;
    text-decoration: none;
  }
  
  .main75l:hover {
    cursor: pointer;
  }
  
  .pc2 {
    margin-left: 50vw;
    margin-top: 1vh;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    border-bottom-left-radius: 2vh;
    border-bottom-right-radius: 2vh;
    background: white;
    width: 31.5vw;
    max-width: 31.5vw;
    min-width: 31.5vw;
    height: 6vh;
    -webkit-animation: fadein2 4s ease 0s normal 1;
            animation: fadein2 4s ease 0s normal 1;
  }
  
  .palettecont {
    height: 5vh;
    margin-top: -1vh;
    /* position: relative; */
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    border-bottom-left-radius: 2vh;
    border-bottom-right-radius: 2vh;
    width: 30vw;
    /* z-index:-3; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 0.5vw;
    /* transform: translateX(-50%); */
  }
  
  .lpurple {
    background: rgb(255, 45, 255);
    border: 1px solid black;
    width: 1.5vmin;
    height: 1.5vmin;
  }
  .purple {
    background-color: rgb(206, 35, 212);
    border: 1px solid black;
    width: 1.5vmin;
    height: 1.5vmin;
  }
  .yellow {
    background-color: rgb(255, 142, 2);
    border: 1px solid black;
    width: 1.5vmin;
    height: 1.5vmin;
  }
  .orange {
    background-color: rgb(255, 91, 1);
    border: 1px solid black;
    width: 1.5vmin;
    height: 1.5vmin;
  }
  .red {
    background-color: rgb(197, 0, 1);
    border: 1px solid black;
    width: 1.5vmin;
    height: 1.5vmin;
  }
  .deepred {
    background-color: rgb(91, 0, 0);
    border: 1px solid black;
    width: 1.5vmin;
    height: 1.5vmin;
  }
  .neon {
    background-color: lawngreen;
    border: 1px solid black;
    width: 1.5vmin;
    height: 1.5vmin;
  }
  .neon2 {
    background-color: mediumseagreen;
    border: 1px solid black;
    width: 1.5vmin;
    height: 1.5vmin;
  }
  .green {
    background-color: rgb(1, 91, 0);
    border: 1px solid black;
    width: 1.5vmin;
    height: 1.5vmin;
  }
  .aqua {
    background-color: rgb(0, 197, 255);
    border: 1px solid black;
    width: 1.5vmin;
    height: 1.5vmin;
  }
  .blue {
    background-color: rgb(0, 143, 255);
    border: 1px solid black;
    width: 1.5vmin;
    height: 1.5vmin;
  }
  .deepblue {
    background-color: rgb(0, 45, 91);
    border: 1px solid black;
    width: 1.5vmin;
    height: 1.5vmin;
  }
  
  .footerh {
    height: auto;
  }
  
  .footer {
    position: absolute;
    text-align: center;
    bottom: 0vh;
    width: 100vw;
    max-width: 100vw;
    top: 85vh;
    margin-bottom: 7vh;
    overflow: visible;
  }
  
  .footer2 {
    position: fixed;
    text-align: center;
    bottom: 0px;
    height: 10vh;
    max-height: 10vh;
    width: 100vw;
    max-width: 100vw;
    margin-bottom: 7vh;
  }
  
  .copy {
    color: white;
    text-decoration: none;
    font-size: 0.7em;
  }
  
  .inst {
    color: white;
    text-decoration: underline;
    margin-top: -2vh;
    font-size: 1em;
  }
  
  .hov:hover {
    cursor: pointer;
  }
  
  .cent {
    text-align: center;
  }
  
  /* .back {
    color: black;
    position: relative;
    content: " ";
    height: 5.25vh;
    width: 13.25vh;
    background: whitesmoke;
    font-size: 3vh;
    font-weight: bold;
    line-height: 3.5vh;
    padding-left: 1vh;
    padding-right: 1vh;
    z-index: 9999;
    display: inline-block;
  } */
  
  .back:hover {
    background: black;
  }
  
  .backwords:hover {
    border: 0.25vh solid whitesmoke;
  }
  
  .backwords {
    position: absolute;
    top: 0.25vh;
    left: 0.25vh;
    width: 12vh;
    height: 4vh;
    /* border-top:1px solid black;
    border-bottom:1px solid black; */
    border: 0.25vh solid black;
    font-size: 3vh;
    font-weight: bold;
    line-height: 3.5vh;
    padding-left: 1vh;
    padding-right: 1vh;
    /* display:inline-block; */
  }
  
  .backwords2 {
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    height: 5.5vh;
    width: 13.8vh;
    background: whitesmoke;
    font-size: 3vh;
    font-weight: bold;
    line-height: 3.5vh;
    padding-left: 1vh;
    padding-right: 1vh;
    z-index: -1;
    /* display:inline-block; */
  }
  
  .lefttri {
    width: 0;
    height: 0;
    border-top: 3vh solid transparent;
    border-bottom: 3vh solid transparent;
    /* border:2px solid black; */
  }
  
  .holder90 {
    display: grid;
    margin-left: 22.5vw;
    grid-template-columns: 12.5vw 25vw 30vw;
    grid-column-gap: 1.5vw;
    grid-template-areas: "ab bb bc";
    /* margin-bottom:10vh; */
  }
  
  .holder75 {
    display: grid;
    margin-left: 22.5vw;
    grid-template-columns: 12.5vw 30vw 25vw;
    /* grid-template-rows: 80vh 20vh; */
    grid-template-areas: "aj bg bc";
    grid-column-gap: 1.5vw;
    /* margin-bottom:10vh; */
  }
  
  .holder90-2 {
    /* margin-left:22.5vw; */
  }
  
  .leftmen {
    grid-area: ab;
    height: 80vh;
    width: 15vw;
    position: relative;
    /* margin-right:2vw; */
    /* display:flex; */
  }
  
  .centerball {
    grid-area: bb;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 0.3vw;
    height: 80vh;
    width: 30vw;
  }
  
  .midDisplaycont {
    flex-grow: 3;
    display: flex;
    align-items: center;
    margin-left: 3.25vw;
  }
  
  .displayball {
    text-align: center;
    color: black;
    z-index: 99;
  }
  
  .midDisplay {
    position: relative;
    top: -5vh;
    /* place-self: center; */
    text-align: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    background-image: -webkit-radial-gradient(
      center top,
      white,
      whitesmoke,
      rgb(150, 150, 150)
    );
    background-image: radial-gradient(
      center top,
      white,
      whitesmoke,
      rgb(150, 150, 150)
    );
    height: 17vw;
    width: 17vw;
    color: black;
    font-size: 9vw;
    line-height: 17vw;
  }
  
  .midDisplay75 {
    position: relative;
    text-align: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    background-image: -webkit-radial-gradient(
      center top,
      white,
      whitesmoke,
      rgb(150, 150, 150)
    );
    background-image: radial-gradient(
      center top,
      white,
      whitesmoke,
      rgb(150, 150, 150)
    );
    height: 17vw;
    width: 17vw;
    color: black;
    font-size: 9vw;
    line-height: 17vw;
  }
  
  .centermenu90 {
    text-align: center;
    color: white;
    /* flex-wrap: wrap; */
    /* justify-content: space-evenly; */
    padding: 0.5vw;
    width: 22vw;
    font-size: 1.4vw;
    height: 20vh;
    position: relative;
    border-radius: 3vh;
    border: 2px solid black;
  }
  
  .centermenu90:before {
    content: " ";
    position: absolute;
    z-index: -1;
    top: -0.5vw;
    left: -0.4vw;
    right: -0.5vw;
    bottom: -0.5vw;
    border-radius: 3vh;
    border: 2vw solid white;
  }
  
  .rightnum {
    grid-area: bc;
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }
  
  .footer90 {
    margin-left: -22.5vw;
  }
  
  .footer75 {
    margin-left: -22.5vw;
    /* position:relative; */
    /* top:-80vh; */
  }
  
  .ballcont {
    /* border-radius: 50%;
    background-color: whitesmoke;
    /* border: 1px solid darkred; */
    /* padding: 0.05vw; */
  
    /* Center the text contents */
    /*display: inline-flex;
    align-items: center;
    justify-content: center;
    color: black;
    background-image: radial-gradient(
      center top,
      white,
      whitesmoke,
      rgb(150, 150, 150)
    );
    width: 2vw;
    height: 2vw;
    font-size: 2vmin;
    font-weight: bold; */
  }
  
  .aball:after {
    /* content: ""; */
  
    /* Ensure the element is a square */
    height: 0;
    width: 100%;
    padding-bottom: 100%;
  }
  
  .aballred {
    /* background-image: radial-gradient(
      center top,
      red,
      whitesmoke,
      rgb(150, 150, 150)
    ); */
    /* color: black; */
    border-radius: 50%;
   /* display: flex;
    justify-content: center;
    align-items: center;
    width: 2vw;
    height: 2vw; */
    /* padding: 0.05vw; */
    /* margin: 0.2vw; */
    /* font-size: 1.3vw; */
    border-color: red;
  
    /* display: inline-flex; */
  }
  
  .ballh {
    padding: 0.2vw;
  }
  
  .ballholder {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0.5vw;
    width: 26vw;
    position: relative;
    border-radius: 3vh;
    /* border: 2px solid black; */
    /* margin-left:1vh; */
  }
  
  .ballholder:after {
    content: " ";
    position: absolute;
    z-index: -1;
    top: -0.5vw;
    left: -0.5vw;
    right: -0.5vw;
    bottom: -0.5vw;
    border-radius: 3vh;
    border: 2vw solid white;
  }
  
  .gameOptions {
    width: 10vw;
    height: 51vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0.5vh;
    justify-content: center;
    /* align-items:center; */
    /* text-align:center; */
    border-radius: 3vh;
    border: 2px solid black;
    margin-left: 0.4vw;
    font-size: 1.3vw;
    color: white;
  }
  
  .gameOptions2 {
    width: 11vw;
    height: 52.5vh;
    position: absolute;
    /* z-index: -2; */
    left: 1vw;
    border-radius: 3vh;
    background: white;
    top: 28vh;
  }
  
  .start90 {
    /* position:fixed; */
    /* top:40vh; */
    /* left:24.5vw; */
    /* z-index:1000; */
    margin-bottom: 2vh;
    /* margin-top: 5vh; */
    margin-left: 2vh;
  }
  
  .start90:hover {
    color: red;
  }
  
  .manual90 {
    margin-bottom: 2vh;
    margin-left: 2vh;
  }
  
  .manual90:hover {
    color: red;
  }
  
  .auto90 {
    margin-left: 2vh;
  }
  
  .auto902:hover {
    color: red;
  }
  
  .timer {
    width: 5vw;
    float: right;
    /* position: relative;
    top: -2vh; */
  }
  
  .ballcont75 {
    border-radius: 50%;
    background-color: whitesmoke;
    /* border: 1px solid darkred; */
    padding: 0.05vw;
  
    /* Center the text contents */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: black;
    background-image: -webkit-radial-gradient(
      center top,
      white,
      whitesmoke,
      rgb(150, 150, 150)
    );
    background-image: radial-gradient(
      center top,
      white,
      whitesmoke,
      rgb(150, 150, 150)
    );
    width: 1.8vw;
    height: 1.8vw;
    font-size: 1.1vw;
    font-weight: bold;
  }
  
  .aball75:after {
    /* content: ""; */
  
    /* Ensure the element is a square */
    height: 0;
    width: 100%;
    padding-bottom: 100%;
  }
  
  .aballred75 {
    border: 1px solid red;
  }
  
  .ballh75 {
    padding-left: 1px;
    padding-bottom: 1px;
  }
  
  .ballholder75 {
    /* display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    padding: 0.5vw;
    width: 12vw;
    height: calc(12vw * 2.8);
    position: relative;
    border-radius: 3vmin; */
    }
  
  .ballholder75:after {
    /* content: " ";
    position: absolute;
    z-index: -1;
    top: -0.5vw;
    left: -0.5vw;
    right: -0.5vw;
    bottom: -0.5vw;
    border-radius: 3vmin;
    border: 2vw solid white; */
  }
  
  .bingoright {
    color: white;
    /* margin-left:2.8vh; */
    text-align: left;
    /* letter-spacing:3.9vh; */
    display: flex;
    width: 12.5vw;
    margin-left: 0.7vw;
    font-size: 2vw;
    justify-content: space-around;
  }
  
  .rightnum75 {
    grid-area: bc;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* align-items: flex-end; */
  }
  
  .centerball75 {
    grid-area: bg;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 0.9vw;
    margin-top: 3vh;
    height: 80vh;
    width: 30vw;
  }
  
  .midDisplaycont75 {
    flex-grow: 3;
    display: flex;
    /* justify-content:center; */
    align-items: center;
    margin-left: 5.5vw;
  }
  
  .displayball75 {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    color: black;
  }
  
  .centermenu75 {
    text-align: center;
    color: white;
    /* flex-wrap: wrap; */
    /* justify-content: space-evenly; */
    padding: 0.5vw;
    width: 26vw;
    font-size: 0.9em;
    height: 20vh;
    position: relative;
    border-radius: 3vh;
    border: 2px solid black;
  }
  
  .centermenu75:before {
    content: " ";
    position: absolute;
    z-index: -1;
    top: -0.5vw;
    left: -0.5vw;
    right: -0.5vw;
    bottom: -0.5vw;
    border-radius: 3vh;
    border: 2vw solid white;
  }
  
  .leftmen75 {
    grid-area: aj;
    height: 80vh;
    position: relative;
    margin-top: 3vh;
    /* margin-right:2vw; */
    /* display:flex; */
  }
  
  .container {
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* justify-content:center; */
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    height: 100vh;
    align-items: center;
    margin-top: -3vh;
    /* overflow: hidden; */
    position: relative;
    /* background:black; */
  }
  
  .print75 {
    margin-top: 2vh;
    width: 40vmin;
    height: 50vmin;
  }
  
  .cardbuilder{
  
  }
  
  .rights {
    font-size: 2.5vh;
    color: white;
    margin-top: 12vh;
    /* animation:fadein 1.3s ease 0.2s normal 1; */
    /* padding-bottom:5vw; */
    /* position: relatie; */
    /* top:-15vw; */
  }
  
  .rights75 {
    font-size: 2.5vmin;
    color: white;
    margin-top: 10vmin;
    /* padding-bottom:5vw; */
    /* position: relative; */
    /* top:-15vw; */
  }
  
  .cardholder {
    /* margin-top: -2vh; */
    height: 40vmin;
    width: 40vmin;
    max-height: 40vmin;
    max-width: 40vmin;
    min-height: 40vmin;
    min-width: 40vmin;
    /* margin-left:10vw; */
    border: 2px solid black;
    position: relative;
    border-radius: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    /* background: mediumorchid; */
    box-shadow: 0 0 0.1vw 0.1vw grey, 0 0 0 0.5vw white;
  }
  
  .card {
    border: 2px dashed black;
    background: white;
    width: 32vmin;
    height: 32vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .inputsh {
    width: 24vmin;
    border: 1px solid black;
    height: 2vmin;
    display: flex;
    align-items: center;
  }
  
  .inputs {
    width: 22vmin;
    line-height: 1vmin;
    font-size: 1vmin;
    font-weight: bolder;
    color: black;
    text-align: center;
    border: 0px solid;
  }
  
  .inputtext {
    line-height: 1vmin;
    font-size: 1.1vmin;
    border: 0px solid;
    text-align: left;
    margin-left: 2vmin;
    /* margin-top:-5vw; */
  }
  
  .bnums {
    text-align: left;
    border: 1px solid black;
    width: 24vmin;
    margin-top: 1vmin;
    /* display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center; */
  }
  
  .bnums2 {
    width: 20vmin;
    margin-left: 2vmin;
    height: 19.9vmin;
    color: black;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }
  
  .logo {
    text-align: right;
    margin-right: 2vmin;
    color: black;
    font-size: 1.1vmin;
  }
  
  .squareh {
    border: 1px solid black;
    text-align: center;
    align-items: center;
    font-weight: bolder;
    line-height: calc(calc(19.9vmin - 12px) / 6);
    font-size: calc(calc(19.9vmin - 12px) / 9);
    height: calc(calc(19.9vmin - 12px) / 6);
    color: black;
    background: white;
  }
  
  .printpal {
    height: 3.5vmin;
    margin-top: 1.5vmin;
    background-color: rgb(176, 69, 204);
    position: absolute;
    /* top:-38.75vw; */
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    border-bottom-left-radius: 2vh;
    border-bottom-right-radius: 2vh;
    width: 35vmin;
    /* z-index:30000; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 2.5vmin;
    box-shadow: 0 0 0.1vw 0.1vw grey, 0 0 0 0.5vw white;
  }
  
  .white {
    background: white;
    border: 1px solid black;
    width: 1.5vmin;
    height: 1.5vmin;
  }
  
  .inst2 {
    margin-left: 9vw;
  }
  
  .instructions {
    color: black;
    background-color: white;
    width: 80vw;
    max-width: 80vw;
    min-width: 80vw;
    font-size: 1.4vw;
    padding-left: 2vw;
    text-align: left;
    overflow-y: scroll;
  }
  
  .printinst {
    font-size: 1.4vw;
    height: 2.2vw;
    margin-top: -1vw;
    margin-left: 1vw;
    margin-bottom: 1.75vw;
    border-radius: 0.7vw;
  }
  
  .print75white {
    background: whitesmoke;
    width: 20.1vmin;
    height: 3.5vmin;
    /* left:50vw; */
    position: relative;
    border-radius: 0.8vmin;
    display: inline-block;
  }
  
  .print90white {
    background: whitesmoke;
    width: 20.1vmin;
    height: 3.5vmin;
    left: 5vmin;
    top: -4vmin;
    position: relative;
    border-radius: 0.8vmin;
    display: inline-block;
  }
  
  .innerprint90 {
    position: absolute;
    left: 0.45vmin;
    top: 0.4vmin;
    height: 2.5vmin;
    width: 19vmin;
    color: white;
    font-size: 1.5vmin;
    line-height: 2.5vmin;
    background-color: rgb(176, 69, 204);
    border-radius: 0.8vmin;
    border: 0.15vw solid black;
    box-shadow: 0 0 1px 1px #777;
  }
  
  .innerprint75 {
    position: absolute;
    left: 0.45vmin;
    top: 0.4vmin;
    height: 2.5vmin;
    width: 19vmin;
    color: white;
    font-size: 1.5vmin;
    line-height: 2.5vmin;
    background-color: rgb(176, 69, 204);
    border-radius: 0.8vmin;
    border: 0.15vmin solid black;
    box-shadow: 0 0 1px 1px #777;
  }
  
  .arrowh {
    display: inline-block;
    position: relative;
    top: -2vmin;
    left: -2vmin;
  }
  
  .icono {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    color: #000;
    box-sizing: border-box;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .icono:after,
  .icono:before {
    content: "";
    box-sizing: border-box;
  }
  .icono2 {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    color: #000;
    box-sizing: border-box;
    top: -4.8vmin;
  }
  .icono2:after,
  .icono2:before {
    content: "";
    box-sizing: border-box;
  }
  .icono-arrow1 {
    width: 3vmin;
    height: 3vmin;
    border-width: 5px 5px 0 0;
    border-style: solid;
    margin: 10px;
  }
  .icono-arrow1:before {
    right: 0;
    top: -3px;
    position: absolute;
    height: 5px;
    box-shadow: inset 0 0 0 32px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    width: 3.3vmin;
    -webkit-transform-origin: right top;
            transform-origin: right top;
  }
  
  .baseline {
    position: relative;
  }
  
  .baseline2 {
    position: relative;
    /* animation:fadein 1.3s ease 0s normal 1; */
  }
  
  .placehead {
    position: absolute;
    white-space: nowrap;
    top: -20vmin;
    left: -20vmin;
    z-index: 1000;
  }
  
  .placehead2 {
    position: absolute;
    white-space: nowrap;
    top: -2vmin;
    left: -5vmin;
    z-index: 1000;
  }
  
  .placetext {
    position: absolute;
    white-space: nowrap;
    top: -5vmin;
    left: -20vmin;
    z-index: 1000;
  }
  
  .placetext2 {
    position: absolute;
    white-space: nowrap;
    top: 15vmin;
    left: -18vmin;
    z-index: 1000;
  }
  
  .selback {
    position: absolute;
    white-space: nowrap;
    top: 13vmin;
    left: -20vmin;
    z-index: 1000;
  }
  
  .selback2 {
    position: absolute;
    white-space: nowrap;
    top: 25vmin;
    left: -5vmin;
    font-size: 0.8vw;
    z-index: 1000;
  }
  
  .numpages {
    display: inline-block;
    width: 1.7vmin;
    height: 1.5vmin;
    position: relative;
    font-size: 1.3vmin;
    /* top:0.07vw; */
    border: 1px solid black;
    text-align: center;
  }
  
  .pages {
    position: absolute;
    top: 10vmin;
    z-index: 1000;
    left: 55vmin;
  }
  
  .pages2 {
    position: absolute;
    top: 10vmin;
    z-index: 1000;
    left: 55vmin;
  }
  
  .gencards {
    position: absolute;
    top: 10vmin;
    z-index: 1000;
    left: 35vmin;
    text-decoration: none;
    color: white;
  }
  
  .gencards2 {
    position: absolute;
    top: 35vmin;
    z-index: 1000;
    right: -15vmin;
    text-decoration: none;
    color: white;
  }
  
  .gencardsburst {
    position: absolute;
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
    top: -28vmin;
    right: -11vmin;
    /* z-index:3000; */
    --burstp: white;
    --burstp2: black;
  }
  
  .gencardsburst:hover {
    --burstp: red;
    --burstp2: white;
    cursor: pointer;
  }
  
  .gencardsburst75 {
    position: absolute;
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
    top: -23vmin;
    right: -6vmin;
    /* z-index:3000; */
    --burstp: white;
    --burstp2: black;
  }
  
  .gencardsburst75:hover {
    --burstp: red;
    --burstp2: white;
    cursor: pointer;
  }
  
  .trianglep75 {
    border-bottom: 10vw solid rgb(176, 69, 204);
    border-left: 5vw solid transparent;
    border-right: 5vw solid transparent;
    position: absolute;
    left: 2.5vw;
    margin-top: 1.045vw;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    height: 0;
    width: 0;
    z-index: 3;
  }
  
  .genrot {
    -webkit-transform: scale(50%);
            transform: scale(50%);
  }
  
  .p75click {
    position: absolute;
    font-size: 2.3vw;
    top: 9.3vw;
    left: 2.5vw;
    z-index: 100;
  }
  
  .triangleap75 {
    border-bottom: 10.5vw solid var(--burstp2);
    border-left: 5vw solid transparent;
    border-right: 5vw solid transparent;
    position: absolute;
    left: 2.5vw;
    margin-top: 0.57075vw;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    height: 0;
    width: 0;
    z-index: 2;
  }
  
  .triangleap752 {
    border-bottom: 11vw solid var(--burstp);
    border-left: 5vw solid transparent;
    border-right: 5vw solid transparent;
    position: absolute;
    left: 2.5vw;
    /* bottom: -7vw; */
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    height: 0;
    width: 0;
    z-index: 1;
  }
  
  .palette:hover {
    cursor: pointer;
  }
  
  .arrowh2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .arrow_box {
    position: relative;
    background: purple;
    border: 4px solid black;
    border-left: 0;
    border-left: initial;
    box-shadow: 2px 2px 0 2px white, 2px -2px 0 2px white;
    padding: 2px;
  }
  
  .tr {
    width: 10vw;
    padding-bottom: calc(10vw / 1.41); /* = width / 1.41 */
    position: relative;
    overflow: hidden;
  }
  .tr:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: purple;
  
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
  
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border: 4px solid black;
    box-shadow: -2px 2px 0 2px white, -1px 1px 1px 1px grey;
  }
  
  /* triangle pointing left */
  .left:before {
    left: 50%;
    width: 50%;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  
  .container2 {
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* justify-content:center; */
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    align-items: center;
    margin-top: -3vh;
    overflow: hidden;
    position: relative;
    background: black;
  }
  
  .print90 {
    /* width: 60vmin; */
    padding-top: 2vh;
    /* animation: invis 1s; */
  }
  
  .cardholder90 {
    /* margin-top: -2vh; */
    height: 30vmin;
    width: 60vmin;
    max-height: 30vmin;
    max-width: 60vmin;
    min-height: 30vmin;
    min-width: 60vmin;
    border: 2px solid black;
    position: relative;
    border-radius: 3vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    /* background: mediumorchid; */
    box-shadow: 0 0 0.1vw 0.1vw grey, 0 0 0 0.5vw white;
    /* animation:slidedown 0.5s ease-out 0.2s normal 1; */
  }
  
  .card90 {
    border: 2px dashed black;
    background: white;
    width: 55vmin;
    height: 25vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .inputsh2 {
    width: 50vmin;
    border: 1px solid black;
    height: 2vmin;
    display: flex;
    align-items: center;
  }
  
  .inputs2 {
    width: 48vmin;
    line-height: 1vmin;
    font-size: 1vmin;
    font-weight: bolder;
    color: black;
    text-align: center;
    border: 0px solid;
  }
  
  .bnums22 {
    text-align: left;
    border: 1px solid black;
    width: 50vmin;
    margin-top: 1vmin;
    /* display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center; */
  }
  
  .inputtext3 {
    line-height: 1vw;
    font-size: 1.1vw;
    border: 0px solid;
    text-align: left;
    margin-left: 2vw;
    /* margin-top:-5vw; */
  }
  
  .bnums23 {
    width: 46vmin;
    margin-left: 2vmin;
    /* height:10vw; */
    color: black;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  
  .logo3 {
    text-align: right;
    margin-right: 2vw;
    color: black;
    font-size: 1.15vw;
  }
  
  .palcontainer {
    display: flex;
    justify-content: center;
    /* animation:fadein 1.3s ease 0.2s normal 1; */
  }
  
  .printpal2 {
    height: 3.5vmin;
    margin-top: 0.5vmin;
    background-color: rgb(176, 69, 204);
    position: absolute;
    /* top:-38.75vw; */
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    border-bottom-left-radius: 2vmin;
    border-bottom-right-radius: 2vmin;
    width: 30vmin;
    /* z-index:30000; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* margin-left:15vw; */
    box-shadow: 0 0 0.1vw 0.1vw grey, 0 0 0 0.5vw white;
  }
  
  .printpage90 {
    position: absolute;
    left: 0.45vw;
    top: 0.4vw;
    height: 2.5vw;
    width: 19vw;
    color: white;
    font-size: 1.5vw;
    line-height: 2.5vw;
    background-color: rgb(176, 69, 204);
    border-radius: 0.8vmin;
    border: 0.15vw solid black;
    box-shadow: 0 0 1px 1px #777;
  }
  
  .pp90 {
  }
  
  .printcontent {
    height: 0px;
    width: 0px;
    position: absolute;
  }
  
*{
	outline: none;
}
body{
	font-family: "Norton";
}
.headerPart {
    text-align: center;
    padding: 30px 15px 20px;
}
.headerPart img {
    width: 280px;
}
.buttonsPart {
    text-align: center;
    position: relative;
}
.primaryButton,
.primaryButton:hover,
.primaryButton:active,
.primaryButton:focus {
    background-color: #6c327f !important;
    border-radius: 0;
    outline: none;
    border: 0;
    color: #fff !important;
    font-size: 20px;
    width: 200px;
    box-shadow: none;
}
.secondayButton,
.secondayButton:hover,
.secondayButton:active,
.secondayButton:focus{
	background-color: #de9524 !important;
    border-radius: 0;
    outline: none;
    border: 0;
    color: #fff !important;
    font-size: 20px;
    width: 200px;
    box-shadow: none;
}
.middleButton{
	margin: 0 20px;
}
.goBackButtonPart{
	text-align: center;
	margin: 25px 0;
}
.mainContentPart {
    margin: 0 auto;
    width: 690px;
}
.mainContentInner {
    float: left;
    width: 100%;
}
.leftContentPart {
    float: left;
    width: 40%;
    padding-lerelativeft: 20px;
}
.numberDisplayPart {
    width: 100%;
    height: 230px;
    text-align: center;
    /* background-image: url('/src/images/number_back.png'); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    font-size: 60px;
    color: #000;
    padding-top: 27%;
}
.nextBallButton {
    text-align: center;
    margin-top: 20px;
}
.centerContentPart {
    float: left;
    width: 23%;
    text-align: center;
    padding-top: 90px;
}
.center90ContentPart{
    float: left;
    width: 20%;
    text-align: center;
    padding-top: 90px;
}
.centerContentPart img,
.center90ContentPart img{
    width: 50px;
    cursor: pointer;
}
.rightContentPart {
    float: left;
    width: 30%;
    padding-right: 20px;
}
.rightContentPart.right90Part{
    width: 30%;
}
.t75_table_title{
	width: 100%;
	float: left;
}
.t75_table_title .t75_title_item {
    float: left;
    width: 20%;
    text-align: center;
    font-size: 13px;
    color: #6c327f;
}
.t75_body,
.t90_body {
    float: left;
    width: 100%;
}
.t75_body_blocks{
	float: left;
    width: 20%;
}
.t75_body_item {
	float: left;
    width: 20%;
    text-align: center;
    font-size: 13px;
    /* background-image: url('/src/images/table_back.png'); */
    background-size: 65% 95%;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 5px;
    color: #000;
}
.t90_body_item{
    float: left;
    width: 10%;
    text-align: center;
    font-size: 13px;
    /* background-image: url('/src/images/table_back.png'); */
    background-size: 65% 95%;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 5px;
    color: #000;
}
.cardGenerationMain{
    padding-top: 40px;
}
.t75_leftPart {
    float: left;
    padding: 0 30px;
    width: 50%;
}
.t75_rightPart {
    float: left;
    width: 46%;
}
.classInput {
    border: 1px solid #6c327f;
    margin-top: 20px;
    width: 100%;
    text-align: center;
    color: #6c327f;
    padding: 8px 15px;
}
.pageNumberPart {
    background-color: #de9524;
    color: #fff;
    text-align: center;
    margin-top: 20px;
    padding: 7px 15px;
    width: 100%;
}
.totalCardInput {
    width: 30px;
    height: 30px;
    text-align: center;
    border: 0px;
}
.generateCardBtn,
.generateCardBtn:hover,
.generateCardBtn:active,
.generateCardBtn:focus{
    margin-top: 20px;
    width: 100%;
    font-size: 17px;
}
.t75_rightPart {
    float: right;
    width: 46%;
    padding: 0 30px;
}
.cardGenerationBack {
    float: left;
    width: 100%;
    background-color: #ff8e02;
    padding: 20px;
}
.t75_cardBody {
    float: left;
    width: 100%;
    background-color: #fff;
}
.card_number_item {
    float: left;
    width: 20%;
    text-align: center;
    padding: 5px;
    font-size: 18px;
    color: #5b5b5b;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.color_picker {
    padding: 30px 45px;
    float: left;
    width: 100%;
}
.color_circle {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 5px;
    float: left;
    cursor: pointer;
}
.t90_rightPart{
    float: left;
    width: 50%;
    padding-top: 45px;
}
.t90_cardBody{
    float: left;
    width: 100%;
    background-color: #fff;
}
.t90_cardBody .card_number_item{
    float: left;
    width: 11.11%;
    text-align: center;
    padding: 12px 5px;
    font-size: 18px;
    color: #5b5b5b;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    height: 50px;
}
.main_page_part {
    float: left;
    width: 100%;
    padding: 50px 45px;
}
.main_page_inner {
    background-color: #f4f0f1;
    text-align: center;
    box-shadow: -5px 6px 10px 5px #ddd;
    color: #5b5b5b;
    padding: 20px;
    float: left;
    width: 100%;
}
.main_page_inner h2{
    font-size: 28px;
}
.main_page_inner h2 .txt_color{
    color: #6c327f;
}
.botttomPart {
    float: left;
    width: 100%;
    padding-top: 50px;
}
.left75Part {
    float: left;
    margin-left: 75px;
    background-color: #6c327f;
    color: #fff;
    padding: 8px;
    border-radius: 5px;
}
.left90Part{
    float: right;
    margin-right: 75px;
    background-color: #6c327f;
    color: #fff;
    padding: 8px;
    border-radius: 5px;
}
.firstLine, .secondLine{
    cursor: pointer;
}
.left75Part a, .left75Part a:hover,
.left90Part a, .left90Part a:hover{
    color: #fff;
    text-decoration: none;
}
.new_pad{
    /* padding: 5px 2px; */
    /* border: 1px solid transparent; */
}
.aballred.new_pad{
    border-color: red;
}
.autoPlaybtn{
    padding-right: 10%;
}
.timercont {
    position: absolute;
    top: 8px;
    right: 30px;
}
.timercont .timer {
    border: 0;
}
@media(max-width: 640px){
    .headerPart{
        padding: 15px 0;
    }
    .headerPart img{
        width: 175px;
    }
    .leftMainContentPart{
        float: left;
        width: 40%;
    }
    .buttonsPart {
        text-align: left;
        float: left;
        width: 100%;
    }
    .primaryButton, .primaryButton:hover, .primaryButton:active, .primaryButton:focus {
        background-color: #6c327f;
        border-radius: 0;
        outline: none;
        border: 0;
        color: #fff;
        font-size: 15px;
        width: 100%;
        box-shadow: none;
        margin-bottom: 10px;
        float: left;
    }
    .middleButton,
    .middleButton:hover,
    .middleButton:active,
    .middleButrelativeton:focus {
        marginrelative-top: 0;
        marginrelative-right: 0;
        marginrelative-left: 0px;
        width:relative 100%;
    }relative
    .goBackButrelativetonPart {
        text-arelativelign: center;
        marginrelative: 0 0 0 0px;
        float: left;
        width: 100%;
    }
    .secondayButton, .secondayButton:hover, .secondayButton:active, .secondayButton:focus {
        background-color: #de9524;
        border-radius: 0;
        outline: none;
        border: 0;
        color: #fff;
        font-size: 15px;
        width: 100%;
        box-shadow: none;
    }
    .mainContentPart{
        width: 100%;
    }
    .leftContentPart{
        padding: 0;
    }
    .centerContentPart{
        width: 20%;
    }
    .rightContentPart{
        padding: 0;
        width: 40%;
    }
    .nextBallButton .primaryButton,
    .nextBallButton .primaryButton:hover,
    .nextBallButton .primaryButton:active,
    .nextBallButton .primaryButton:focus{
        width: 100%;
    }
    .centerContentPart img, .center90ContentPart img{
        width: 30px;
    }
    .numberDisplayPart{
        height: 145px;
    }
    .nextBallButton{
        margin-top: 0px;    
    }
    .t90_body_item{
        width: 20%;
    }
    .cardGenerationMain{
        padding-top: 0px;
    }
    .t75_rightPart{
        width: 100%;
        padding: 0px;
    }
    .t75_leftPart{
        width: 100%;
        padding: 0px;
    }
    .t75_leftPart .generateCardBtn,
    .t75_leftPart .generateCardBtn:hover,
    .t75_leftPart .generateCardBtn:active,
    .t75_leftPart .generateCardBtn:focus{
        width: 100%;
        margin-top: 10px;
    }
    .classInput{
        margin-top: 10px;
    }
    .pageNumberPart{
        margin-top: 10px;
    }
    .color_picker {
        padding: 10px 0px;
        width: 360px;
        margin: 0 auto;
        float: none;
    }
    .color_circle{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        cursor: pointer;
        margin: 0 4px;
        float: left;
    }
    .t90_rightPart {
        float: left;
        width: 100%;
        padding-top: 0;
    }
    .main_page_part{
        padding: 50px 0;
    }
    .leftContentPart{
        width: 100%;
    }
    .centerContentPart,.center90ContentPart{
        padding-top: 175px;
    }
}
@media(max-width: 480px){
    .main_page_inner h2{
        font-size: 20px;
    }
    .left75Part{
        margin-left: 0;
    }
    .left90Part{
        margin-right: 0;
    }
}
@media(max-width: 360px){
    .primaryButton, .primaryButton:hover, .primaryButton:active, .primaryButton:focus {
        background-color: #6c327f;
        border-radius: 0;
        outline: none;
        border: 0;
        color: #fff;
        font-size: 15px;
        width: 100%;
        box-shadow: none;
        margin-bottom: 10px;
        float: left;
    }
    .color_picker {
        padding: 10px 0px;
        width: 100%;
        margin: 0 auto;
    }
}
