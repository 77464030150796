*{
	outline: none;
}
body{
	font-family: "Norton";
}
.headerPart {
    text-align: center;
    padding: 30px 15px 20px;
}
.headerPart img {
    width: 280px;
}
.buttonsPart {
    text-align: center;
    position: relative;
}
.primaryButton,
.primaryButton:hover,
.primaryButton:active,
.primaryButton:focus {
    background-color: #6c327f !important;
    border-radius: 0;
    outline: none;
    border: 0;
    color: #fff !important;
    font-size: 20px;
    width: 200px;
    box-shadow: none;
}
.secondayButton,
.secondayButton:hover,
.secondayButton:active,
.secondayButton:focus{
	background-color: #de9524 !important;
    border-radius: 0;
    outline: none;
    border: 0;
    color: #fff !important;
    font-size: 20px;
    width: 200px;
    box-shadow: none;
}
.middleButton{
	margin: 0 20px;
}
.goBackButtonPart{
	text-align: center;
	margin: 25px 0;
}
.mainContentPart {
    margin: 0 auto;
    width: 690px;
}
.mainContentInner {
    float: left;
    width: 100%;
}
.leftContentPart {
    float: left;
    width: 40%;
    padding-lerelativeft: 20px;
}
.numberDisplayPart {
    width: 100%;
    height: 230px;
    text-align: center;
    /* background-image: url('/src/images/number_back.png'); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    font-size: 60px;
    color: #000;
    padding-top: 27%;
}
.nextBallButton {
    text-align: center;
    margin-top: 20px;
}
.centerContentPart {
    float: left;
    width: 23%;
    text-align: center;
    padding-top: 90px;
}
.center90ContentPart{
    float: left;
    width: 20%;
    text-align: center;
    padding-top: 90px;
}
.centerContentPart img,
.center90ContentPart img{
    width: 50px;
    cursor: pointer;
}
.rightContentPart {
    float: left;
    width: 30%;
    padding-right: 20px;
}
.rightContentPart.right90Part{
    width: 30%;
}
.t75_table_title{
	width: 100%;
	float: left;
}
.t75_table_title .t75_title_item {
    float: left;
    width: 20%;
    text-align: center;
    font-size: 13px;
    color: #6c327f;
}
.t75_body,
.t90_body {
    float: left;
    width: 100%;
}
.t75_body_blocks{
	float: left;
    width: 20%;
}
.t75_body_item {
	float: left;
    width: 20%;
    text-align: center;
    font-size: 13px;
    /* background-image: url('/src/images/table_back.png'); */
    background-size: 65% 95%;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 5px;
    color: #000;
}
.t90_body_item{
    float: left;
    width: 10%;
    text-align: center;
    font-size: 13px;
    /* background-image: url('/src/images/table_back.png'); */
    background-size: 65% 95%;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 5px;
    color: #000;
}
.cardGenerationMain{
    padding-top: 40px;
}
.t75_leftPart {
    float: left;
    padding: 0 30px;
    width: 50%;
}
.t75_rightPart {
    float: left;
    width: 46%;
}
.classInput {
    border: 1px solid #6c327f;
    margin-top: 20px;
    width: 100%;
    text-align: center;
    color: #6c327f;
    padding: 8px 15px;
}
.pageNumberPart {
    background-color: #de9524;
    color: #fff;
    text-align: center;
    margin-top: 20px;
    padding: 7px 15px;
    width: 100%;
}
.totalCardInput {
    width: 30px;
    height: 30px;
    text-align: center;
    border: 0px;
}
.generateCardBtn,
.generateCardBtn:hover,
.generateCardBtn:active,
.generateCardBtn:focus{
    margin-top: 20px;
    width: 100%;
    font-size: 17px;
}
.t75_rightPart {
    float: right;
    width: 46%;
    padding: 0 30px;
}
.cardGenerationBack {
    float: left;
    width: 100%;
    background-color: #ff8e02;
    padding: 20px;
}
.t75_cardBody {
    float: left;
    width: 100%;
    background-color: #fff;
}
.card_number_item {
    float: left;
    width: 20%;
    text-align: center;
    padding: 5px;
    font-size: 18px;
    color: #5b5b5b;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.color_picker {
    padding: 30px 45px;
    float: left;
    width: 100%;
}
.color_circle {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 5px;
    float: left;
    cursor: pointer;
}
.t90_rightPart{
    float: left;
    width: 50%;
    padding-top: 45px;
}
.t90_cardBody{
    float: left;
    width: 100%;
    background-color: #fff;
}
.t90_cardBody .card_number_item{
    float: left;
    width: 11.11%;
    text-align: center;
    padding: 12px 5px;
    font-size: 18px;
    color: #5b5b5b;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    height: 50px;
}
.main_page_part {
    float: left;
    width: 100%;
    padding: 50px 45px;
}
.main_page_inner {
    background-color: #f4f0f1;
    text-align: center;
    box-shadow: -5px 6px 10px 5px #ddd;
    color: #5b5b5b;
    padding: 20px;
    float: left;
    width: 100%;
}
.main_page_inner h2{
    font-size: 28px;
}
.main_page_inner h2 .txt_color{
    color: #6c327f;
}
.botttomPart {
    float: left;
    width: 100%;
    padding-top: 50px;
}
.left75Part {
    float: left;
    margin-left: 75px;
    background-color: #6c327f;
    color: #fff;
    padding: 8px;
    border-radius: 5px;
}
.left90Part{
    float: right;
    margin-right: 75px;
    background-color: #6c327f;
    color: #fff;
    padding: 8px;
    border-radius: 5px;
}
.firstLine, .secondLine{
    cursor: pointer;
}
.left75Part a, .left75Part a:hover,
.left90Part a, .left90Part a:hover{
    color: #fff;
    text-decoration: none;
}
.new_pad{
    /* padding: 5px 2px; */
    /* border: 1px solid transparent; */
}
.aballred.new_pad{
    border-color: red;
}
.autoPlaybtn{
    padding-right: 10%;
}
.timercont {
    position: absolute;
    top: 8px;
    right: 30px;
}
.timercont .timer {
    border: 0;
}
@media(max-width: 640px){
    .headerPart{
        padding: 15px 0;
    }
    .headerPart img{
        width: 175px;
    }
    .leftMainContentPart{
        float: left;
        width: 40%;
    }
    .buttonsPart {
        text-align: left;
        float: left;
        width: 100%;
    }
    .primaryButton, .primaryButton:hover, .primaryButton:active, .primaryButton:focus {
        background-color: #6c327f;
        border-radius: 0;
        outline: none;
        border: 0;
        color: #fff;
        font-size: 15px;
        width: 100%;
        box-shadow: none;
        margin-bottom: 10px;
        float: left;
    }
    .middleButton,
    .middleButton:hover,
    .middleButton:active,
    .middleButrelativeton:focus {
        marginrelative-top: 0;
        marginrelative-right: 0;
        marginrelative-left: 0px;
        width:relative 100%;
    }relative
    .goBackButrelativetonPart {
        text-arelativelign: center;
        marginrelative: 0 0 0 0px;
        float: left;
        width: 100%;
    }
    .secondayButton, .secondayButton:hover, .secondayButton:active, .secondayButton:focus {
        background-color: #de9524;
        border-radius: 0;
        outline: none;
        border: 0;
        color: #fff;
        font-size: 15px;
        width: 100%;
        box-shadow: none;
    }
    .mainContentPart{
        width: 100%;
    }
    .leftContentPart{
        padding: 0;
    }
    .centerContentPart{
        width: 20%;
    }
    .rightContentPart{
        padding: 0;
        width: 40%;
    }
    .nextBallButton .primaryButton,
    .nextBallButton .primaryButton:hover,
    .nextBallButton .primaryButton:active,
    .nextBallButton .primaryButton:focus{
        width: 100%;
    }
    .centerContentPart img, .center90ContentPart img{
        width: 30px;
    }
    .numberDisplayPart{
        height: 145px;
    }
    .nextBallButton{
        margin-top: 0px;    
    }
    .t90_body_item{
        width: 20%;
    }
    .cardGenerationMain{
        padding-top: 0px;
    }
    .t75_rightPart{
        width: 100%;
        padding: 0px;
    }
    .t75_leftPart{
        width: 100%;
        padding: 0px;
    }
    .t75_leftPart .generateCardBtn,
    .t75_leftPart .generateCardBtn:hover,
    .t75_leftPart .generateCardBtn:active,
    .t75_leftPart .generateCardBtn:focus{
        width: 100%;
        margin-top: 10px;
    }
    .classInput{
        margin-top: 10px;
    }
    .pageNumberPart{
        margin-top: 10px;
    }
    .color_picker {
        padding: 10px 0px;
        width: 360px;
        margin: 0 auto;
        float: none;
    }
    .color_circle{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        cursor: pointer;
        margin: 0 4px;
        float: left;
    }
    .t90_rightPart {
        float: left;
        width: 100%;
        padding-top: 0;
    }
    .main_page_part{
        padding: 50px 0;
    }
    .leftContentPart{
        width: 100%;
    }
    .centerContentPart,.center90ContentPart{
        padding-top: 175px;
    }
}
@media(max-width: 480px){
    .main_page_inner h2{
        font-size: 20px;
    }
    .left75Part{
        margin-left: 0;
    }
    .left90Part{
        margin-right: 0;
    }
}
@media(max-width: 360px){
    .primaryButton, .primaryButton:hover, .primaryButton:active, .primaryButton:focus {
        background-color: #6c327f;
        border-radius: 0;
        outline: none;
        border: 0;
        color: #fff;
        font-size: 15px;
        width: 100%;
        box-shadow: none;
        margin-bottom: 10px;
        float: left;
    }
    .color_picker {
        padding: 10px 0px;
        width: 100%;
        margin: 0 auto;
    }
}